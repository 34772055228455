import { reactive } from "vue";

const theme = reactive({
  agentLogo: "gordon-corp.svg",
  devLogo: "mantaray-full.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "0ebfceb0-ad66-4310-8be9-20c44c34f75c",
  videoLibraryId: "94447",
  streamCdnUrl: "https://vz-4e510198-3e0.b-cdn.net",
  streamApiKey: "58a9ae1e-35ad-4ef6-86211e8c9f5a-adb3-49d5",
  storageApiPassword: "84baefee-adf1-4a26-aa50bc3f99dc-6e29-4ad9",
  cdnUrl: "https://themantaray.metaluxe.com.au",
  devFolder: "/mantaray",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4k_dashboard.mp4",
  dashboardImg: "theme/dashboard/dashboard.jpg",
  
  // top level menu
  assetsMenuOrder: [
    'demo',
    'amenities',
    'exterior-building',
    'stroll-to-ritz',
    'penthouse'
  ],
  
  demoMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  amenitiesMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  
  exteriorBuildingMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],
  
  strollToRitzMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],

  penthouseMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans'
  ],

  demoAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },
  
  amenitiesAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },
  
  exteriorBuildingAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },

  strollToRitzAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },

  penthouseAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    balcony: [],
    balconyName: [],
    balconyUrl: []
  },
});

export default { theme };