<template>
  <DashboardVideo />
  <div class="container d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
      <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
        <router-link
          :to="{
            name: componentAdmin
          }"
          class="btn-back opacity-0"
        >
        </router-link>
        <a href="#" class="m-auto"><img style="height: 50px;" :src="`/img/ui-theme/` + theme.devLogo" /></a>
        <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><img src="/img/ui-theme/contact-icon-rev.svg" alt="Fullscreen"></button>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center z-2">
        <div class="row g-0">
          <div class="col-12">
            <router-link
            v-for="assetType in assetTypes"
            :key="assetType.Guid"
            :to="{
              name:  componentName,
              params: {
                assetType: assetType.LinkName
              }
            }"
            class="btn-theme-menu mb-2">
              {{ assetType.ObjectName }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex align-items-end justify-content-end sticky-bottom theme-footer">
        <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo"/>
      </div>
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import DashboardVideo from "@/components/DashboardVideo.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
    name: "DashboardHome",
    components: {
        DashboardVideo,
        OffCanvas
    },
    data() {
        return {
        assetTypes: null,
        componentName: "AssetType",
        componentAdmin: "Admin",
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'),
        brochureUrl: "https://florian.metaluxe.com.au/brochure/MCO0024_Master%20brochure%2048pp_320x240_V9%20(2).pdf"
        }
    },
    created() {
        BunnyNetService.getFolders(this.folderStructure)
        .then(response => {
        // Remap array
        const menuItems = response.data.map((item) => {
            return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
            };
        });

        const customMenu = sortArray(menuItems, {
            by: ['LinkName'],
            order: 'LinkName',
            customOrders: {
            LinkName: this.theme.assetsMenuOrder
            }
        })
        
        this.assetTypes = customMenu
        console.log(response)
        console.log(this.assetTypes)
        })
        .catch(error => {
        console.log(error)
        })
    },
    setup () {
        const {theme} = global

        return {
        theme
        }
    }
};
</script>
