<template>
  <ViewTopNav :heading="this.viewType" :subheading="this.assetType" />
  
  <div class="position-absolute p-content z-1">
			<div class="col-12 p-0 z-1 h-100">
				<div class="row g-0 h-100">

          <div class="col-12 col-md-6 col-lg-4 m-auto" v-for="asset in assetTypes" :key="asset.guid">
						<div class="row g-0">
							<div class="col-12 position-relative z-1">

                <!-- Videos -->
                <router-link
                  v-if="viewType === 'videos' && collectionId"
                  :to="{
                    name:  'VideoView',
                    params: {
                      guid: asset.guid,
                      collectionId: asset.videoLibraryId
                    }
                  }"
                  class="v-link"
                >
                  <img :src="theme.streamCdnUrl + '/' + asset.guid + '/' + asset.thumbnailFileName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.title }}
									</div>
                </router-link>

                <!-- Renders -->
                <router-link
                  v-if="viewType === 'renders' || viewType === 'range'"
                  :to="{
                    name:  'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }"
                  class="r-link"
                >
                  <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.title }}
									</div>
                </router-link>

                <!-- Panoramas -->
                <router-link
                  v-if="viewType === 'panoramas' || viewType === 'virtual-tour' || viewType === 'balcony-views'"
                  :to="{
                    name:  'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }"
                  class="p-link"
                >
                  <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.title }}
									</div>
                </router-link>

                <!-- Floorplans -->
                <router-link
                  v-if="viewType === 'floorplans'"
                  :to="{
                    name:  'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }"
                  class="f-link"
                >
                  <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.title }}
									</div>
                </router-link>

							</div>
						</div>
					</div>
          
      </div>
    </div>
  </div>
  
  <ViewBotNav />
  <OffCanvas />
</template>

<script>
import ViewTopNav from '@/components/ViewTopNav.vue';
import ViewBotNav from '@/components/ViewBotNav.vue';
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global';
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetView",
  props: [
    'assetType',
    'viewType',
    'lid',
    'collectionId'
  ],
  components: {
    ViewTopNav,
    ViewBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      videoStatus: false,
      assetNames: null,
      assetUrls: null
    }
  },
  created() {
    if (this.assetType === 'demo') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.demoAsset.videos
        this.assetNames = this.theme.demoAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.demoAsset.floorplans
        this.assetNames = this.theme.demoAsset.floorplansName
        this.assetUrls = this.theme.demoAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.demoAsset.panoramas
        this.assetNames = this.theme.demoAsset.panoramasName
        this.assetUrls = this.theme.demoAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.demoAsset.balcony
        this.assetNames = this.theme.demoAsset.balconyName
        this.assetUrls = this.theme.demoAsset.balconyUrl
      }
    } else if (this.assetType === 'amenities') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.amenitiesAsset.videos
        this.assetNames = this.theme.amenitiesAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.amenitiesAsset.floorplans
        this.assetNames = this.theme.amenitiesAsset.floorplansName
        this.assetUrls = this.theme.amenitiesAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.amenitiesAsset.panoramas
        this.assetNames = this.theme.amenitiesAsset.panoramasName
        this.assetUrls = this.theme.amenitiesAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.amenitiesAsset.balcony
        this.assetNames = this.theme.amenitiesAsset.balconyName
        this.assetUrls = this.theme.amenitiesAsset.balconyUrl
      }
    } else if (this.assetType === 'exterior-building') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.exteriorBuildingAsset.videos
        this.assetNames = this.theme.exteriorBuildingAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.exteriorBuildingAsset.floorplans
        this.assetNames = this.theme.exteriorBuildingAsset.floorplansName
        this.assetUrls = this.theme.exteriorBuildingAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.exteriorBuildingAsset.panoramas
        this.assetNames = this.theme.exteriorBuildingAsset.panoramasName
        this.assetUrls = this.theme.exteriorBuildingAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.exteriorBuildingAsset.balcony
        this.assetNames = this.theme.exteriorBuildingAsset.balconyName
        this.assetUrls = this.theme.exteriorBuildingAsset.balconyUrl
      }
    } else if (this.assetType === 'stroll-to-ritz') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.strollToRitzAsset.videos
        this.assetNames = this.theme.strollToRitzAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.strollToRitzAsset.floorplans
        this.assetNames = this.theme.strollToRitzAsset.floorplansName
        this.assetUrls = this.theme.strollToRitzAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.strollToRitzAsset.panoramas
        this.assetNames = this.theme.strollToRitzAsset.panoramasName
        this.assetUrls = this.theme.strollToRitzAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.strollToRitzAsset.balcony
        this.assetNames = this.theme.strollToRitzAsset.balconyName
        this.assetUrls = this.theme.strollToRitzAsset.balconyUrl
      }
    } else if (this.assetType === 'penthouse') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.penthouseAsset.videos
        this.assetNames = this.theme.penthouseAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.penthouseAsset.floorplans
        this.assetNames = this.theme.penthouseAsset.floorplansName
        this.assetUrls = this.theme.penthouseAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.penthouseAsset.panoramas
        this.assetNames = this.theme.penthouseAsset.panoramasName
        this.assetUrls = this.theme.penthouseAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.penthouseAsset.balcony
        this.assetNames = this.theme.penthouseAsset.balconyName
        this.assetUrls = this.theme.penthouseAsset.balconyUrl
      }
    }

    // Video type
    if(this.viewType === 'videos') {
      BunnyNetService.getVideos(this.lid, this.collectionId)
      .then(response => {
        // Remap array (inject asset names)
        let j = 0;
        const menuItems = response.data.items.map((item) => {
            if (this.assetUrls && this.assetUrls.length >= 1){
				return {
					guid: item.guid,
					videoLibraryId: item.videoLibraryId,
					thumbnailFileName: item.thumbnailFileName,
					ObjectName: item.ObjectName,
					title: this.assetNames[j],
					id: j++
				};
			} else {
				return {
					guid: item.guid,
					videoLibraryId: item.videoLibraryId,
					thumbnailFileName: item.thumbnailFileName,
					ObjectName: item.ObjectName,
					title: unslugify(item.title.split('.').slice(0,-1).join(".")).replace("hyphen","-"),
					id: j++
				};
			}
        });

        // Custom order array
        if ( this.assetOrder && this.assetOrder.length >= 1  ) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems;
        }

        this.videoStatus = true
      })
      .catch(error => {
        console.log(error)
      })
    // Renders, Panoramas, Floorplans type
    } else {
      BunnyNetService.getRenders(this.folderStructure, this.assetType)
      .then(response => {
        let j = 0;
        // Remap array
        const menuItems = response.data.map((item) => {
            if (this.assetUrls && this.assetUrls.length >= 1){
                return {
                    Guid: item.Guid,
                    ObjectName: unslugify(item.ObjectName),
                    LinkName: item.ObjectName,
                    Path: item.Path,
                    IsDirectory: item.IsDirectory,
                    StorageZoneName: item.StorageZoneName,
                    title: this.assetNames[j],
                    url: this.assetUrls[j],
                    id: j++
                };
            }else {
                return {
                    Guid: item.Guid,
                    ObjectName: unslugify(item.ObjectName),
                    LinkName: item.ObjectName,
                    Path: item.Path,
                    IsDirectory: item.IsDirectory,
                    StorageZoneName: item.StorageZoneName,
                    title: unslugify(item.ObjectName.split('.').slice(0,-1).join(".")).replace("hyphen","-"),
                    url: [],
                    id: j++
                };
          }
        });

        // Custom order array
        if( this.assetOrder && this.assetOrder.length >= 1 ) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems
        }
      })
    }
  },
  methods: {
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>
